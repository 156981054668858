.
<template>
  <div class="main">
    <p class="header">单据信息</p>
    <table class="detail-form">
      <tr>
        <td class="countdownWrap">
          <span>订货单状态：{{ tradeOrderVo.statusExplain }}</span>
        </td>
        <td>订货金额：{{ tradeOrderVo.purchaseAmount }}</td>
        <td v-if="isMemberStore">供应商：{{ tradeOrderVo.supplierName }}</td>
        <td v-if="!isMemberStore">
          创建时间：{{ dayjs(tradeOrderVo.time).format("YYYY-MM-DD HH:mm:ss") }}
        </td>
      </tr>
      <tr v-if="isMemberStore">
        <td>创建时间：{{ dayjs(tradeOrderVo.time).format("YYYY-MM-DD HH:mm:ss") }}</td>
      </tr>
    </table>
    <p class="header">商品信息</p>
    <div class="detail-form">
      <span>订货单编号：{{ tradeOrderVo.supplyNo }} &nbsp;</span>
      <span v-if="isMemberStore">&nbsp;关联单号：{{ tradeOrderVo.orderNo }}</span>
      <el-button
        v-if="tradeOrderVo?.canDeliver && !isMemberStore"
        type="primary"
        size="small"
        style="float: right; margin-bottom: 8px"
        @click="onDeliverGoods"
      >
        发 货
      </el-button>
    </div>
    <el-table
      :data="goodsData"
      border
      :header-row-style="{ 'font-weight': 100, color: '#0D0202' }"
      :row-style="{ 'font-size': '14px', color: '#0D0202' }"
      :header-cell-style="{ 'font-weight': 'bold', background: '#F0F2F8' }"
      @selection-change="handleSelectionChange"
    >
      <el-table-column prop="goodsId" label="商品ID">
        <el-table-column prop="goodsId" label="总计" width="200" />
      </el-table-column>
      <el-table-column prop="goodsName" label="商品名称">
        <el-table-column prop="goodsName" />
      </el-table-column>
      <el-table-column prop="skuName" label="商品规格">
        <el-table-column prop="skuName" />
      </el-table-column>
      <el-table-column prop="purchasePrice" label="订货价（元）">
        <el-table-column prop="purchasePrice" />
      </el-table-column>
      <el-table-column prop="purchaseCount" label="订货数">
        <el-table-column prop="purchaseCount" :label="tradeOrderVo.totalPurchaseCount" />
      </el-table-column>
      <el-table-column prop="purchaseAmount" label="商品金额（元）">
        <el-table-column prop="purchaseAmount" :label="tradeOrderVo.purchaseAmount" />
      </el-table-column>
      <!-- <el-table-column label="已发货数/订货数">
        <template slot-scope="{row}">
          <span>{{ Number(row.shipmentCount) }} / {{ Number(row.sellCount) }}</span>
        </template>
      </el-table-column> -->
      <el-table-column label="售后状态">
        <el-table-column>
          <template slot-scope="{ row }">
            <span>{{ row.refundStatusExplain || "无" }}</span>
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>
    <p class="detail-form">买家留言：{{ tradeOrderVo.buyerMemo || "无" }}</p>
    <!-- 收件人信息 用户信息 -->
    <!-- <UserMessage
      :order-detail="orderDetail"
    /> -->
    <p class="header">
      收货人信息
      <!-- <template v-if="tradeOrderVo.isAllDeliver === 0">
        <el-button
          v-if="tradeOrderVo.isAuditModifyReceive"
          type="primary"
          size="small"
          plain
          @click="handleAuditAddress(props.orderDetail?.tradeOrderVo)"
        >
          改地址待处理
        </el-button>
        <el-tag
          v-else
          type="success"
        >
          改地址已同意
        </el-tag>
      </template> -->
    </p>
    <el-table
      :data="orderDetail"
      border
      :header-row-style="{ 'font-weight': 100, color: '#0D0202' }"
      :row-style="{ 'font-size': '14px', color: '#0D0202' }"
      :header-cell-style="{ 'font-weight': 'bold', background: '#F0F2F8' }"
    >
      <el-table-column prop="receiverName" label="收货人" />
      <el-table-column prop="receiverMobile" label="联系方式" />
      <el-table-column prop="shippingTypeExplain" label="配送方式" />
      <el-table-column prop="fullAddress" label="收货地址" />
    </el-table>

    <p class="header">物流信息</p>
    <el-table
      :data="trackData"
      border
      :header-row-style="{ 'font-weight': 100, color: '#0D0202' }"
      :row-style="{ 'font-size': '14px', color: '#0D0202' }"
      :header-cell-style="{ 'font-weight': 'bold', background: '#F0F2F8' }"
    >
      <el-table-column prop="logisticNo" label="物流单号" />
      <el-table-column prop="logisticName" label="物流公司" />
      <el-table-column prop="createTime" label="发货时间">
        <template slot-scope="{ row }">
          <p>
            {{ dayjs(row.deliverTime).format("YYYY-MM-DD HH:mm:ss") }}
          </p>
        </template>
      </el-table-column>
      <el-table-column label="商品">
        <template slot-scope="{ row }">
          <p v-for="item in row.supplyOrderGoodsLogisticsVOList" :key="item.id">
            {{ item.goodsName }}
            <span>（x{{ item.shipmentCount }}）</span>
          </p>
        </template>
      </el-table-column>
      <el-table-column label="物流状态">
        <template slot-scope="{ row }">
          <el-button type="text" @click="handleTrack(row)">
            {{ row.logisticStatusExplain }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-button class="back" @click="$router.go(-1)"> 返 回 </el-button>
    <!-- 订单发货弹窗 -->
    <DeliverOrderDialog
      v-model="showDeliverModel"
      :order-data="orderData"
      type="add"
      @success="deliverSuccess"
    />
  </div>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance, computed } from "vue";
import dayjs from "dayjs";
import DeliverOrderDialog from "./components/deliver-order-dialog.vue";
// import {
//   ORDER_STATUS_MAP,
//   REFUND_STATUS_MAP,
// } from '@/views/nb/order/normals';

const app = getCurrentInstance().proxy;
const { $route, $router } = app;
const tradeOrderVo = ref({}); // 订单信息
const goodsData = ref([]); // 商品信息
const subOrderIdList = ref([]); // 选中的子订单id
const orderDetail = ref([]); // 订单详情
const trackData = ref([]); // 物流信息
const showDeliverModel = ref(false); // 发货弹窗
const orderData = ref(null); // 发货弹窗数据
const { isMemberStore } = JSON.parse(localStorage.getItem("userInfo"));
// 计算属性简写方式(计算属性不可被修改)
const orderId = computed(() => {
  return $route.query.orderId;
});

const handleSelectionChange = (val) => {
  subOrderIdList.value = val.map((item) => item.subOrderId);
};

const getDetail = async () => {
  const res = await app.$axios.get(app.$api.supplierOrderOrder.detail, {
    params: {
      id: orderId.value,
    },
  });
  orderDetail.value = [
    {
      receiverName: res.data.receiverName,
      receiverMobile: res.data.receiverMobile,
      shippingTypeExplain: res.data.shippingTypeExplain,
      fullAddress: res.data.fullAddress,
    },
  ];
  tradeOrderVo.value = res.data;
  goodsData.value = res.data.supplyOrderGoodsVOList;
  trackData.value = res.data.supplyOrderLogisticsVOList;
};

const deliverSuccess = () => {
  getDetail();
};

const onDeliverGoods = () => {
  orderData.value = tradeOrderVo.value;
  showDeliverModel.value = true;
};

const handleTrack = ({ logisticNo, logisticCode, logisticName }) => {
  const routeData = app.$router.resolve({
    path: "/nb/order/refund/track",
    query: {
      logisticNo,
      logisticCode,
      logisticName,
      orderId: tradeOrderVo.value.orderId,
    },
  });
  window.open(routeData.href, "_blank");
};

// const handleAuditAddress = () => {
//   app.$router.push({
//     path: '/nb/order/address',
//     query: {
//       orderNo: tradeOrderVo.value.orderNo,
//     },
//   });
// };

onMounted(() => {
  getDetail();
});
</script>

<style lang="scss" scoped>
.main {
  background: #fff;
  padding: 20px;
  .back {
    margin-top: 20px;
  }
}
.header {
  border-left: 3px solid #409eff;
  padding: 12px;
  font-size: 18px;
  margin: 20px 0;
  .tip {
    font-size: 12px;
    color: #999;
  }
  .title {
    display: inline-block;
    margin-right: 50px;
  }
}
.detail-form {
  margin-top: 10px;
  width: 100%;
  font-size: 14px;
  margin-bottom: 10px;
  td {
    padding: 12px 16px;
  }
}
.countdownWrap {
  position: relative;
}
.countdown {
  color: red;
  position: absolute;
  top: 12px;
  white-space: nowrap;
}
.order-tips {
  font-size: 14px;
  border: 1px solid #ebeef5;
  padding: 20px;
  text-align: center;
}
.goods {
  display: flex;
  img {
    width: 70px;
    height: 70px;
  }
  p {
    padding: 0 12px;
  }
  .id {
    color: #736e6e;
  }
}
.remark {
  padding: 12px 8px;
  border: 1px solid #dfe6ec;
  margin-bottom: 10px;
}
</style>
