var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c("p", { staticClass: "header" }, [_vm._v("单据信息")]),
      _c("table", { staticClass: "detail-form" }, [
        _c("tr", [
          _c("td", { staticClass: "countdownWrap" }, [
            _c("span", [
              _vm._v(
                "订货单状态：" + _vm._s(_setup.tradeOrderVo.statusExplain)
              ),
            ]),
          ]),
          _c("td", [
            _vm._v("订货金额：" + _vm._s(_setup.tradeOrderVo.purchaseAmount)),
          ]),
          _setup.isMemberStore
            ? _c("td", [
                _vm._v("供应商：" + _vm._s(_setup.tradeOrderVo.supplierName)),
              ])
            : _vm._e(),
          !_setup.isMemberStore
            ? _c("td", [
                _vm._v(
                  " 创建时间：" +
                    _vm._s(
                      _setup
                        .dayjs(_setup.tradeOrderVo.time)
                        .format("YYYY-MM-DD HH:mm:ss")
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
        ]),
        _setup.isMemberStore
          ? _c("tr", [
              _c("td", [
                _vm._v(
                  "创建时间：" +
                    _vm._s(
                      _setup
                        .dayjs(_setup.tradeOrderVo.time)
                        .format("YYYY-MM-DD HH:mm:ss")
                    )
                ),
              ]),
            ])
          : _vm._e(),
      ]),
      _c("p", { staticClass: "header" }, [_vm._v("商品信息")]),
      _c(
        "div",
        { staticClass: "detail-form" },
        [
          _c("span", [
            _vm._v(
              "订货单编号：" + _vm._s(_setup.tradeOrderVo.supplyNo) + "  "
            ),
          ]),
          _setup.isMemberStore
            ? _c("span", [
                _vm._v(" 关联单号：" + _vm._s(_setup.tradeOrderVo.orderNo)),
              ])
            : _vm._e(),
          _setup.tradeOrderVo?.canDeliver && !_setup.isMemberStore
            ? _c(
                "el-button",
                {
                  staticStyle: { float: "right", "margin-bottom": "8px" },
                  attrs: { type: "primary", size: "small" },
                  on: { click: _setup.onDeliverGoods },
                },
                [_vm._v(" 发 货 ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-table",
        {
          attrs: {
            data: _setup.goodsData,
            border: "",
            "header-row-style": { "font-weight": 100, color: "#0D0202" },
            "row-style": { "font-size": "14px", color: "#0D0202" },
            "header-cell-style": {
              "font-weight": "bold",
              background: "#F0F2F8",
            },
          },
          on: { "selection-change": _setup.handleSelectionChange },
        },
        [
          _c(
            "el-table-column",
            { attrs: { prop: "goodsId", label: "商品ID" } },
            [
              _c("el-table-column", {
                attrs: { prop: "goodsId", label: "总计", width: "200" },
              }),
            ],
            1
          ),
          _c(
            "el-table-column",
            { attrs: { prop: "goodsName", label: "商品名称" } },
            [_c("el-table-column", { attrs: { prop: "goodsName" } })],
            1
          ),
          _c(
            "el-table-column",
            { attrs: { prop: "skuName", label: "商品规格" } },
            [_c("el-table-column", { attrs: { prop: "skuName" } })],
            1
          ),
          _c(
            "el-table-column",
            { attrs: { prop: "purchasePrice", label: "订货价（元）" } },
            [_c("el-table-column", { attrs: { prop: "purchasePrice" } })],
            1
          ),
          _c(
            "el-table-column",
            { attrs: { prop: "purchaseCount", label: "订货数" } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "purchaseCount",
                  label: _setup.tradeOrderVo.totalPurchaseCount,
                },
              }),
            ],
            1
          ),
          _c(
            "el-table-column",
            { attrs: { prop: "purchaseAmount", label: "商品金额（元）" } },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "purchaseAmount",
                  label: _setup.tradeOrderVo.purchaseAmount,
                },
              }),
            ],
            1
          ),
          _c(
            "el-table-column",
            { attrs: { label: "售后状态" } },
            [
              _c("el-table-column", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(row.refundStatusExplain || "无")),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("p", { staticClass: "detail-form" }, [
        _vm._v("买家留言：" + _vm._s(_setup.tradeOrderVo.buyerMemo || "无")),
      ]),
      _c("p", { staticClass: "header" }, [_vm._v(" 收货人信息 ")]),
      _c(
        "el-table",
        {
          attrs: {
            data: _setup.orderDetail,
            border: "",
            "header-row-style": { "font-weight": 100, color: "#0D0202" },
            "row-style": { "font-size": "14px", color: "#0D0202" },
            "header-cell-style": {
              "font-weight": "bold",
              background: "#F0F2F8",
            },
          },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "receiverName", label: "收货人" },
          }),
          _c("el-table-column", {
            attrs: { prop: "receiverMobile", label: "联系方式" },
          }),
          _c("el-table-column", {
            attrs: { prop: "shippingTypeExplain", label: "配送方式" },
          }),
          _c("el-table-column", {
            attrs: { prop: "fullAddress", label: "收货地址" },
          }),
        ],
        1
      ),
      _c("p", { staticClass: "header" }, [_vm._v("物流信息")]),
      _c(
        "el-table",
        {
          attrs: {
            data: _setup.trackData,
            border: "",
            "header-row-style": { "font-weight": 100, color: "#0D0202" },
            "row-style": { "font-size": "14px", color: "#0D0202" },
            "header-cell-style": {
              "font-weight": "bold",
              background: "#F0F2F8",
            },
          },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "logisticNo", label: "物流单号" },
          }),
          _c("el-table-column", {
            attrs: { prop: "logisticName", label: "物流公司" },
          }),
          _c("el-table-column", {
            attrs: { prop: "createTime", label: "发货时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _setup
                              .dayjs(row.deliverTime)
                              .format("YYYY-MM-DD HH:mm:ss")
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "商品" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return _vm._l(
                    row.supplyOrderGoodsLogisticsVOList,
                    function (item) {
                      return _c("p", { key: item.id }, [
                        _vm._v(" " + _vm._s(item.goodsName) + " "),
                        _c("span", [
                          _vm._v("（x" + _vm._s(item.shipmentCount) + "）"),
                        ]),
                      ])
                    }
                  )
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "物流状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _setup.handleTrack(row)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(row.logisticStatusExplain) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-button",
        {
          staticClass: "back",
          on: {
            click: function ($event) {
              return _setup.$router.go(-1)
            },
          },
        },
        [_vm._v(" 返 回 ")]
      ),
      _c(_setup.DeliverOrderDialog, {
        attrs: { "order-data": _setup.orderData, type: "add" },
        on: { success: _setup.deliverSuccess },
        model: {
          value: _setup.showDeliverModel,
          callback: function ($$v) {
            _setup.showDeliverModel = $$v
          },
          expression: "showDeliverModel",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }